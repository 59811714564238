<template>
  <tr v-if="hideOutOfCoverage(ruleset.label)">
    <td class="type-width">
      <alert-type-label :type="ruleset.type"></alert-type-label>
    </td>
    <td class="event-width">{{ ruleset.transformedLabel }}</td>
    <td class="right-align sound-width">{{ isSoundActive }}</td>
    <td class="right-align edit-width">
      <span v-if="isHardAlert">-</span>
      <button class="edit-button" type="button" @click="openModalForEditingRule" v-else > Edit </button>
    </td>
  </tr>
</template>

<script>
import AlertTypeLabel from './AlertTypeLabel.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  props: ['ruleset'],
  components: {
    'alert-type-label': AlertTypeLabel
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      getCurrentCustomerNew: 'login/getCurrentCustomerNew',
      userNew: 'login/getUserNew'
    }),
    isHardAlert () {
      if (this.ruleset.type === 'FALL') {
        return true
      }
      return false
    },
    isSoundActive () {
      return this.ruleset.sound ? 'Yes' : 'No'
    }
  },
  methods: {
    ...mapActions({
      editCommunityRule: 'notifications/editCommunityRule'
    }),
    hideOutOfCoverage (ruleset) {
      return ruleset !== 'outOfCoverageDuringInRoomOrNightTime'
    },
    async openModalForEditingRule () {
      await this.editCommunityRule(this.ruleset)
    }
  }
}
</script>

<style scoped>
tr {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  border-bottom: solid 1px #ccc;
}
td {
  font-size: 14px;
  padding: auto 0;
}
.center-align {
  min-width: 90px;
  text-align: center;
}
.right-align {
  text-align: right;
  padding-right: 1em;
}
.edit-button {
  color: rgba(0, 148, 255, 1);
}
.edit-button:focus {
  outline: none;
}
.cursor {
  cursor: pointer;
}
.type-width {
  min-width: 90px;
}
.sound-width {
  min-width: 100px;
}
.edit-width {
  min-width: 230px;
}
.event-width {
  width: 100%;
}
</style>
