<template>
  <section>
    <h2>{{ $t('notification.notifications') }}</h2>
    <div v-if="loading && !rulesets.length">
      <v-progress-circular
        class="center-align"
        :size="70"
        :width="7"
        color="grey"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else-if="!loading && !rulesets.length">
      {{ $t('notFound') }}
    </div>
    <table v-else>
      <thead>
        <tr class="full-width">
          <th class="type-width">{{ $t('notification.type') }}</th>
          <th class="min-width event-width">{{ $t('notification.event') }}</th>
          <th class="right-align sound-width">
            {{ $t('notification.sound') }}
          </th>
          <th class="center-align edit-width">{{ $t('notification.configuration') }}</th>
        </tr>
      </thead>
      <tbody>
        <alert-configuration
          v-for="ruleset in rulesets"
          :key="ruleset.id"
          :ruleset="ruleset"
          :loading="loading"
        ></alert-configuration>
        <tr>
          <td colspan="5"></td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import AlertConfiguration from '../../components/AlertConfiguration/AlertConfiguration.vue'
import { mapActions, mapGetters } from 'vuex'
import CrudMsgProcessor from '@/components/notifications/crud_msgs'
import ServerMsgProcessor from '@/components/notifications/server_msgs'

export default {
  components: {
    'alert-configuration': AlertConfiguration
  },
  data () {
    return {
      loading: false,
      rulesets: []
    }
  },
  computed: {
    ...mapGetters({
      currentCustomer: 'login/getCurrentCustomer',
      currentFulfiller: 'login/getCurrentFulfiller',
      newToken: 'login/getNewToken',
      getCurrentCustomerNew: 'login/getCurrentCustomerNew',
      userNew: 'login/getUserNew',
      communityRulesets: 'notifications/communityRulesets'
    })
  },
  methods: {
    ...mapActions({
      getAndSetCommunityRulesets: 'notifications/getAndSetCommunityRulesets'
    }),
    async fetchEventRulesets () {
      try {
        this.loading = true
        const id = this.getCurrentCustomerNew[0] || this.userNew.clientId
        if (id) {
          await this.getAndSetCommunityRulesets(id)
        }
      } catch (error) {
        this.loading = false
      }
    }
  },
  async mounted () {
    try {
      await this.fetchEventRulesets()
    } catch (error) {
      handleErr(error)
    }
  },
  watch: {
    async currentCustomer () {
      await this.fetchEventRulesets()
    },
    async newToken (newVal) {
      if (newVal) {
        await this.fetchEventRulesets()
      }
    },
    async communityRulesets (newRulesets) {
      if (newRulesets.length) {
        this.rulesets = newRulesets
      }
      this.loading = false
    }
  }
}

function handleErr (err) {
  err?.response?.status ? CrudMsgProcessor('err', err) : ServerMsgProcessor('', err)
}
</script>

<style scoped>
section {
  box-sizing: border-box;
  font-size: 12px;
  padding: 0 1.5em;
  text-align: left;
}
h2 {
  font-size: 2rem;
  margin: 0.5em 0 1em;
}
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  padding: 0 2em;
}
th {
  padding: 1em 0.5em 2em;
}
th:first-child {
  width: 90px;
  padding-left: 0;
}
th:nth-child(2) {
  padding-left: 0;
}
th:last-child {
  text-align: right;
}
tr {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
div {
  width: 100px;
  margin: 0 auto;
  font-size: 20px;
}
.center-align {
  text-align: center;
}
.right-align {
  text-align: right;
  padding-right: 1em;
}
.full-width {
  width: 100%;
}
.type-width {
  min-width: 90px;
}
.sound-width {
  min-width: 100px;
}
.edit-width {
  min-width: 230px;
}
.event-width {
  width: 100%;
}
</style>
